import React from "react";
import PropType from 'prop-types';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import styled from 'styled-components'
import { graphql, Link } from "gatsby";

import Layout from "../components/layout/ca";
import SEO from "../components/seo";

const BlogEntry = (props) => {
	const { data: { wordpressPost: post } } = props;
	return (
		<Layout location={props.location}>
			<Helmet
				title={post.title}
				meta={[
					{ name: 'description', content: post.excerpt }				
				]}
			/>
			<ContainerArticle>
				{ post.featured_media && (
				<MediaArticle>
					<Img
						src={post.featured_media.localFile.childImageSharp.sizes.src}
						sizes={post.featured_media.localFile.childImageSharp.sizes}
						className="img-fluid"
						alt={post.title}
					/>
				</MediaArticle>
				)}
				<ContentArticle>
					<h1 dangerouslySetInnerHTML={{ __html: post.title }} />
	        
					
					<p dangerouslySetInnerHTML={{ __html: post.content }} />
					<span>{post.categories && post.categories.map(category => category.name)}</span>
	        <h3>{post.author.name}</h3>
	        <i className="fa fa-clock-o" />
	        {' '}
					Publicat:
					{' '}
					{post.date} 
				</ContentArticle>
			</ContainerArticle>
		</Layout>
	);
};

export default BlogEntry;

const ContainerArticle = styled.article`
	@media (max-width: 992px){
		margin-top: 7vh;
	}
`
const MediaArticle = styled.div`
	width: 50%;
	position: fixed;
	@media (max-width: 992px) {
	  position: relative;
	  width: 100%;
	}
`
const ContentArticle = styled.div`
	margin-top: 14vh;
	margin-bottom: 14vh;
	width: 45%;
	float: right;
	margin-right: 50px;
	margin-left: 20px;
	@media (max-width: 992px) {
	  width: 100%;
	  margin: 0;
	  margin-top: 7vh;
	  margin-bottom: 7vh;
	}
`

export const pageQuery = graphql`
	query($id: String!) {
		wordpressPost(id: { eq: $id }) {
			title
			content
			excerpt
			date(formatString: "DD, MM YYYY")
			categories {
				id
				name
			}
			tags {
				id
				name
			}
			author {
				name
				description
				avatar_urls {
					wordpress_48
				}
			}
			featured_media {
				localFile {
					childImageSharp {
						id
						sizes( maxWidth: 800 ) {
							...GatsbyImageSharpSizes
						}
					}
				}
			}
			slug
		}
	}
`;
